






















































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import { cloneDeep, debounce, filter, find, findIndex, forEach, isArray, isString, lowerCase, some } from 'lodash';
import { OncoSearchConfig, PharmaSearchConfig } from '../NovaGroupTransfer/index.config';
import { DepartmentService } from '@/services/department-service';
import { UserGroupConditionType } from '@/views/userGroup/config';

@Component({
})
export default class NovaUserGroupSelector extends Vue {
    private isShowPostTree: boolean = false;
    // tree
    public postTree: any[] = [];
    public defaultProps = {
        children: 'children',
        label: 'name'
    }
    public defaultCheckedKeys: any[] = [];
    public defaultExpandedKeys: any[] = [];
    public effectMembersCount: number = 0;
    public defaultDisabledDepartmentID: any = [];
    // nonff select
    public selectOptions: any[] = [];
    public helpOptions: any[] = [
        { name: '全部', id: 'All', selsct: false },
        { name: '是', id: 'Y', selsct: false },
        { name: '否', id: 'N', selsct: false },
    ];
    public BUOptions: any[] = [];
    public selectVal: string = "";
    public selectValCopy: string = "";
    public helpVal: string = 'All';
    public BuVal: any[] = [];
    public BuValCopy: any[] = [];
    public loading: boolean = false;
    public filterText: string = '';
    public placeholder: string = '搜索岗位、员工号、员工姓名、邮箱';
    // public quickQueryUserList = debounce(this.queryUser, 800);
    public removedDropdownlist: Dict<boolean> = {};
    public searchConfig: Dict<any> = [];
    private fillIntervalId!: number;
    // 初始化数组
    private showDropSearch: boolean = false; // 搜搜菜单
    // 左侧可选
    private searchResults!: Dict<any[]>;
    private searchForm: any = {};
    private initDataCount!: number; // 通过搜索获取的数组长度 每次搜索后重新赋值
    private isSearch: boolean = true; // 是否搜索的道德flag 默认false

    // search results
    public showDepartmentSearch: boolean = false; // 搜搜菜单
    public searchLoading: boolean = false;
    public deptSearchList: any = [];
    public memberSearchList: any = [];
    public deptCheckList: any = [];
    public memberCheckList: any = [];

    private userGroupCondition: any = [];

    @Model('change', { type: Array, default: () => [] })
    private rightInitData!: any[];

    @Prop({ required: true })
    private userGroupRoles!: Dict<any>;

    @Prop({ required: true })
    private type!: string;

    @Inject(UserGroupService)
    private userGroupService!: UserGroupService;

    // i8n
    @Inject(I18nService)
    private i18nSvc!: I18nService;

    @Inject(DepartmentService)
    private departmentService!: DepartmentService;


    @Watch('rightInitData', { deep: true })
    public async handleInitUserGroupCondition(value: Dict<any>): Promise<void> {
        this.loading = true;
        this.userGroupCondition = cloneDeep(value);
        // await this.getSearchOption({});
        this.initUserGroupConditions();
        this.disabledChildrenOfData(this.postTree, false);
        this.getDefaultCheckedKeys(value, this.postTree);
        this.postTree = cloneDeep(this.postTree);
        if (this.showDropSearch) {
            this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, value)
            this.updateCheckBoxDisableValue();
        }
        this.loading = false;
        // await this.initEmployeeData();
    }

    // @Watch('userGroupRoles')
    // public async handleinitUserGroupRoles(value: Dict<any>): Promise<void> {
    //   this.showDropSearch = true;
    //   // this.searchForm = {};
    //   await this.getSearchOption({});
    //   this.leftSearch = '';
    //   this.searchForm = value;
    //   this.removedDropdownlist = {};
    //   forEach(this.searchForm, (item, key) => {
    //     this.removedDropdownlist[key] = isArray(item) && item.length > 0;
    //   });
    // }

    get i18n(): Dict<any> {
        return this.i18nSvc.i18nData;
    }

    get isComfirmButtonDisabled(): boolean {
        let isdisabled: boolean = true;
        forEach(this.searchForm, (item, key) => {
            if (isArray(item) && item.length > 0) {
                isdisabled = false;
            }
        });
        return isdisabled;
    }
    // roleNonfflist
    // 获取nonff 角色下拉信息
    public async getroleNonfflist(): Promise<void> {
        // roleNonfflist
        var param = '';
        let result = await this.departmentService.roleNonfflist(param)
        this.selectOptions = result;
    }
    // 获取nonff BU下拉信息
    public async getBuNonfflist(): Promise<void> {
        // roleNonfflist
        var param = '';
        let result = await this.departmentService.BuNonfflist(param)
        this.BUOptions = result;
    }
    // BUOptions
    public async mounted(): Promise<void> {
        this.loading = true;
        if (this.rightInitData) {
            this.userGroupCondition = cloneDeep(this.rightInitData);
        }
        await this.getroleNonfflist()
        await this.getBuNonfflist()
        await this.initEmployeeData();
        // await this.getSearchOption({});
        this.initUserGroupConditions();
        this.loading = false;
    }

    public initUserGroupConditions() {
        let groupCondition = isArray(this.userGroupCondition) ? this.userGroupCondition.filter((condition: any) => condition.type == UserGroupConditionType.GROUP) : [];
        if (groupCondition.length == 1) {
            this.searchForm = groupCondition[0].group;
            this.helpVal = this.searchForm.helpSelect;
            this.BuVal = this.searchForm.buList;
            console.log('244', this.searchForm)
            forEach(this.searchForm, (item, key) => {
                this.removedDropdownlist[key] = isArray(item) && item.length > 0;
            });
        } else {
            this.resetSearch();
        }
        // this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
    }
    private async initEmployeeData() {
        let res: any[] = [];
        if (this.type == 'Pharma') {
            res = (await this.departmentService.getPharmaTree()) || [];
        }
        if (this.type == 'NONFF') {
            res = (await this.departmentService.getNunFFTree()) || [];
        }
        if (res && isArray(res)) {
            this.postTree = res;
        }
        // 初始化选中的节点
        // this.getDefaultExpandedKeys(this.departmentTree);
        this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
        this.loading = false;
    }

    public clearSearch(): void {
        if (!this.filterText && this.helpVal == 0 && this.selectVal == '' && this.BuVal.length <= 0) {
            this.showDropSearch = false;
            this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
        }
    }

    public getLabel(
        options: Dict<any>[],
        val: string,
        key: string,
        removeUnexists?: boolean
    ): string | undefined {
        const target = find(options, (e) => e.value === val);
        if (!target) {
            const index = findIndex(this.searchForm[key], (e) => e === val);
            if (index > -1) {
                if (removeUnexists) {
                    this.searchForm[key].splice(index, 1);
                }
                if (this.searchForm[key].length === 0) {
                    this.removedDropdownlist[key] = false;
                }
            }
            return;
        } else {
            return target.label || target.value;
        }
    }

    // 搜索dropdown
    public dropdownSearch(): void {
        // filterText
        this.loading = true;
        if (this.filterText) {
            this.showDropSearch = true
            this.deptCheckList = []
            this.memberCheckList = []
            this.queryUserAndDepartByText()
            this.loading = false;
        }
        // else if (this.selectVal || this.helpVal || this.BuVal) {
        //     this.showDropSearch = true
        //     this.deptCheckList = []
        //     this.memberCheckList = []
        //     this.queryUserAndDepartByText()
        //     this.loading = false;
        // } else {
        //     this.showDropSearch = false
        //     this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree)
        //     this.loading = false;
        // }
    }
    // 下拉选择 直接搜索
    public selectRole(): void {
        this.BuValCopy = [];
        if (this.BuVal.length > 0) {
            for (var i = 0; i < this.BuVal.length; i++) {
                if (this.BuValCopy.indexOf(encodeURIComponent(this.BuVal[i])) == -1) {
                    this.BuValCopy.push(encodeURIComponent(this.BuVal[i]))
                } else {

                }
            }
        }
        if (this.selectVal) {
            this.selectValCopy = encodeURIComponent(this.selectVal)
        }
        if (!this.filterText && this.helpVal == 0 && this.selectVal == '' && this.BuVal.length <= 0) {
            this.showDropSearch = false;
            this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
        } else {
            this.dropdownSearch()
        }

    }
    // 搜索确定
    public async confirmSearch(): Promise<void> {
        this.loading = true;
        this.searchForm.buList = this.BuVal;
        // selected: this.selectValCopy,
        //         helpSelect: this.helpVal,
        //         buList: this.BuValCopy
        this.searchForm.helpSelect = this.helpVal;
        let searchParm = cloneDeep(this.searchForm);
        searchParm.division = this.type;
        let searchRes = (await this.departmentService.getNunffPositionEffectiveCount(searchParm));
        this.effectMembersCount = searchRes;
        console.log('550',searchRes)
        let effectUserNumber = this.effectMembersCount;
        // this.userGroupCondition.push(Object.assign({}, {
        //     dept: { deptCode: '', deptName: '' },
        //     type: 'post',
        //     effectUserNumbers: effectUserNumber
        // }));
        // this.effectMembersCount = 0
        let newGroupCondition = Object.assign({}, {
            type: 'group',
            effectUserNumbers: effectUserNumber,
            group: cloneDeep(this.searchForm)
        });
        let groupIndex = this.userGroupCondition.findIndex((item: any) => item.type === UserGroupConditionType.GROUP);
        if (groupIndex === -1) {
            this.userGroupCondition.push(newGroupCondition);
        } else {
            this.userGroupCondition.splice(groupIndex, 1);
            this.userGroupCondition.push(newGroupCondition);
        }
        this.$notify({
            title: '成功',
            message: '筛选添加成功',
            type: 'success'
        });
        this.loading = false;
        this.$emit('change', this.userGroupCondition);
    }

  public resetSearch(): void {
    this.BuVal = [];
    this.helpVal = 'All';
    this.searchForm.buList = this.BuVal;
    this.searchForm.helpSelect = this.helpVal;
    forEach(this.searchForm, (item, key) => {
      if (key !== 'division') {
        this.searchForm[key] = [];
      }
    });
    this.removedDropdownlist = {};
    this.getSearchOption();
  }

    public handleCloseTag(index: number, propKey: string): void {
        if (
            isArray(this.searchForm[propKey]) &&
            this.searchForm[propKey].length > 0
        ) {
            this.searchForm[propKey].splice(index, 1);
            this.handleNoTag(this.searchForm[propKey], propKey);
        }
    }

    public handleOptionsChange(isOpen: boolean, propKey: string): void {
        // if (isOpen) {
        //   const target = find(this.searchConfig, (item) => item.prop === propKey);
        //   target.options = this.searchResults[propKey];
        // }
        if (
            !isOpen &&
            isArray(this.searchForm[propKey]) &&
            this.searchForm[propKey].length > 0
        ) {
            this.removedDropdownlist[propKey] = true;
            this.getSearchOption();
        }
    }

    public handleNoTag(val: string[], propKey: string): void {
        if (isArray(val) && val.length === 0) {
            this.removedDropdownlist[propKey] = false;
            this.getSearchOption();
        }
    }
    public destroy(): void {
        clearInterval(this.fillIntervalId);
    }

    // 获取筛选下拉
    private async getSearchOption(val?: Dict<any>): Promise<void> {
        this.loading = true;
        var data_: any = '';
        this.searchConfig =
            this.type === 'Pharma' ? PharmaSearchConfig : OncoSearchConfig;
        // this.searchResults = await this.userGroupService.postcondition(
        //     'pharma',
        //     data_
        // );
        this.searchResults = await this.userGroupService.postcondition(
            'pharma',
            val ? val : this.searchForm
        );
        // const keys = Object.keys(res);
        // this.searchConfig.forEach((item: any) => {
        //   item.options = this.searchResults[item.prop];
        // });
        this.startFillSearchConf();
        this.loading = false;
    }

    private startFillSearchConf(): void {
        clearInterval(this.fillIntervalId);
        forEach(this.searchConfig, (item: any) => {
            item.options = [];
        });
        let index = 0;
        this.pushSearchResults(index);
        index += 50;
        this.fillIntervalId = setInterval(() => {
            this.pushSearchResults(index);
            // forEach(that.searchConfig, (item: any) => {
            //   if ((that.searchResults[item.prop] as any[]).length < index) {
            //     item.options.push(...(that.searchResults[item.prop] as any[]).slice(index, index + 50));
            //   }
            // });
            index += 50;
        }, 500);
    }

    private pushSearchResults(index: number): void {
        for (const item of this.searchConfig as any) {
            if ((this.searchResults[item.prop] as any[]) && (this.searchResults[item.prop] as any[]).length > index) {
                item.options.push(
                    ...(this.searchResults[item.prop] as any[]).slice(index, index + 50)
                );
                // forEach(this.searchForm[item.prop], (val) => {
                //   if (some(item.options, (op) => op.value === val)) {
                //     this.getLabel(item.options, val, item.prop, true);
                //   }
                // });
            } else {
                forEach(this.searchForm[item.prop], (val) => {
                    // if (some(item.options, (op) => op.value === val)) {
                    this.getLabel(item.options, val, item.prop, true);
                    // }
                });
            }
        }
    }

    public changeSelectorStyle() {
        this.isShowPostTree = !this.isShowPostTree;
    }
    // control tree data
    public async handleCheckChange(data: any, treeStatus: any) {
        this.searchLoading = true;
        if (treeStatus.checkedKeys.includes(data.dept_id)) {
            if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) === -1) {
                this.defaultCheckedKeys.push(data.dept_id)
            }
            if (data.children && data.children.length > 0) {
                // 1. 父节点的所有子节点disabled,
                await this.disabledChildrenOfData(data.children, true)
                // 2. 判断是否在hrCoreData, 如果在，需要从hrCoreData中移除，并且取消该节点的选中
                await this.removeCheckedChildrenOfData(data.children)
                // 3. 计算父节点的所有子节点个数，即生效人数
                await this.getEffectUserNumbers(data)
                let effectUserNumber = this.effectMembersCount
                this.effectMembersCount = 0
                // 3. 并入hrCoreData

                this.userGroupCondition.push(Object.assign({}, {
                    dept: { deptCode: data.dept_id, deptName: data.dept_name },
                    type: 'post',
                    effectUserNumbers: effectUserNumber
                }));

            } else {
                // 没有子节点的节点直接并入hrCoreData
                await this.getEffectUserNumbers(data)
                let effectUserNumber = this.effectMembersCount;
                this.userGroupCondition.push(Object.assign({}, {
                    dept: { deptCode: data.dept_id, deptName: data.dept_name },
                    type: 'post',
                    effectUserNumbers: effectUserNumber
                }));
                this.effectMembersCount = 0
            }

        } else {
            if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) !== -1) {
                this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id), 1)
            }
            if (this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === data.dept_id) !== -1) {
                // 1. 遍历hrCoreData，从hrCoreData中把取消选中的节点删除
                this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === data.dept_id), 1);
                // 2. 判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
                if (data.children && isArray(data.children) && data.children.length > 0) {
                    await this.disabledChildrenOfData(data.children, false)
                }
            }
        }
        this.searchLoading = false;
        this.$emit('change', this.userGroupCondition);
    }
    // 确认有效员工数
    public async SuregetEffectUserNumbers(data: any) {
        console.log('点击确认', data)
        let
            param = {
                selected: this.selectValCopy,
                helpSelect: this.helpVal,
                buList: this.BuValCopy
            }
        let result = await this.departmentService.getNunffPositionEffectiveCount(param)
        this.effectMembersCount = result
        console.log('550')
        let effectUserNumber = this.effectMembersCount;
        this.userGroupCondition.push(Object.assign({}, {
            dept: { deptCode: '', deptName: '' },
            type: 'post',
            effectUserNumbers: effectUserNumber
        }));
        console.log('560', this.userGroupCondition)
        this.effectMembersCount = 0
    }
    // 获取有效员工数
    public async getEffectUserNumbers(data: any) {
        console.log('num接口', data)
        let param = { deptCode: data.dept_id };
        // if (this.type == 'Pharma') {
        //     let result = await this.departmentService.getPharmaPositionEffectiveCount(param)
        //     this.effectMembersCount = result
        // }
        // else
        if (this.type == 'NONFF') {
            let result = await this.departmentService.getNunffPositionEffectiveCount(param)
            this.effectMembersCount = result
        }
        else {
            let result = await this.departmentService.getOncoPositionEffectiveCount(param)
            this.effectMembersCount = result
        }
        // let result = await this.departmentService.getMembersOfDepart({ deptId: data.id, divisionCode: ''})
        // let result = await this.departmentService.getDepartMembersByID(data.dept_id);
        // this.effectMembersCount = isArray(result) && result.length ? result.length : 0;
        // console.log(result)
    }

    public getDefaultCheckedKeys(userGroupCondition: any, departmentTree: any) {
        let newDefaultCheckedKeys: any = [], newDefaultExpandedKeys: any = [];
        if (departmentTree && isArray(departmentTree) && departmentTree.length > 0) {
            departmentTree.forEach((tree: any) => {
                if (tree.children && isArray(tree.children) && tree.children.length > 0) {
                    tree.children.forEach((childTree: any) => {
                        newDefaultExpandedKeys.push(childTree.dept_id);
                    });
                } else {
                    newDefaultExpandedKeys.push(tree.dept_id);
                }
            });
        }
        if (userGroupCondition && isArray(userGroupCondition) && userGroupCondition.length > 0) {
            userGroupCondition.forEach((item: any) => {
                if (item.type === UserGroupConditionType.POST) {
                    if (newDefaultCheckedKeys.findIndex((key: any) => item.type == UserGroupConditionType.POST && key === item.dept.deptCode) === -1) {
                        newDefaultCheckedKeys.push(item.dept.deptCode);
                        newDefaultExpandedKeys.push(item.dept.deptCode);
                    }
                    // 判断是否有子节点，并且把子节点disabled
                    this.getChildrenCheckedKeys(item.dept.deptCode, departmentTree);
                }
            });
        }
        console.log('defaultCheckedKeys', this.defaultCheckedKeys)
        this.defaultCheckedKeys = newDefaultCheckedKeys;
        this.defaultExpandedKeys = newDefaultExpandedKeys;

    }

    public getChildrenCheckedKeys(deptId: any, itemData: any) {
        itemData.forEach((item: any) => {
            if (item.dept_id === deptId) {
                let children = item.children;
                if (children && isArray(children) && children.length > 0) {
                    children.forEach((child: any) => {
                        this.$set(child, 'disabled', true);
                        let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
                        if (index == -1) {
                            this.defaultDisabledDepartmentID.push(child.dept_id);
                        }
                        if (child.children && isArray(child.children) && child.children.length > 0) {
                            this.getChildrenCheckedKeysOfNode(child.children);
                        }
                    });
                }
            } else {
                let children = item.children;
                if (children && isArray(children) && children.length > 0) {
                    this.getChildrenCheckedKeys(deptId, children);
                }
            }
        });
    }

    public getChildrenCheckedKeysOfNode(children: any) {
        children.forEach((child: any) => {
            this.$set(child, 'disabled', true)
            let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
            if (index == -1) {
                this.defaultDisabledDepartmentID.push(child.dept_id);
            }
            if (child.children && isArray(child.children) && child.children.length > 0) {
                this.getChildrenCheckedKeysOfNode(child.children)
            }
        })

    }

    public disabledChildrenOfData(children: any, flag: boolean) {
        if (isArray(children) && children.length !== 0) {
            children.forEach((item: any) => {
                this.$set(item, 'disabled', flag);
                if (flag) {
                    let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
                    if (index == -1) {
                        this.defaultDisabledDepartmentID.push(item.dept_id);
                    }
                } else {
                    let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
                    if (index !== -1) {
                        this.defaultDisabledDepartmentID.splice(index, 1);
                    }
                }
                if (item.children && isArray(item.children) && item.children.length > 0) {
                    this.disabledChildrenOfData(item.children, flag)
                } else {
                    this.disabledChildrenOfData([], flag)
                }
            })
        }
    }

    public removeCheckedChildrenOfData(children: any) {
        if (isArray(children) && children.length !== 0) {
            children.forEach((child: any) => {
                let index = this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === child.dept_id);
                if (index !== -1) {
                    this.userGroupCondition.splice(index, 1)
                    if (this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id) !== -1) {
                        this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id), 1)
                    }
                    (this.$refs as any).tree2.setChecked(child, false, true)
                } else {
                    if (child.children && isArray(child.children) && child.children.length > 0) {
                        this.removeCheckedChildrenOfData(child.children)
                    }
                }
            })
        }
    }

    public changeMemberChecked(memberItem: any) {
        this.searchLoading = true;
        // 判断选中的memberItem是否在checkedlist中，如果不在，则是取消，需要从hrCoreData中删除该条数据；如果在，则加入hrCoreData
        let index = this.memberCheckList.findIndex((item: any) => item === memberItem.userId)
        if (index !== -1) {
            this.userGroupCondition.push(Object.assign({}, memberItem, {
                type: 'user',
                effectUserNumbers: 1,
                user: { userId: memberItem.userId, userName: memberItem.fullName }
            }));
        } else {
            this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.USER && item.user.userId === memberItem.userId), 1)
        }
        this.searchLoading = false;
        this.$emit('change', this.userGroupCondition);
    }

    public async changeDeptChecked(dept: any) {
        this.searchLoading = true;
        let index = this.deptCheckList.findIndex((item: any) => item === dept.deptCode);
        if (index !== -1) {
            let result: any;
            let param = { deptCode: dept.deptCode };
            // if (this.type == 'NONFF') {
            result = await this.departmentService.getNunffPositionEffectiveCount(param)
            this.effectMembersCount = result
            // }
            let effectUserNumbersCount = result ? result : 0;
            this.userGroupCondition.push(Object.assign({}, {
                dept: { deptCode: dept.deptCode, deptName: dept.deptName },
                type: 'post',
                effectUserNumbers: effectUserNumbersCount
            }));
            this.findAndCheckCurrentNode(dept.deptCode, this.postTree);
            this.updateCheckBoxDisableValue();
            this.searchLoading = false;

        } else {
            this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === dept.deptCode), 1);
            this.findAndDeleteCurrentNode(dept.deptCode, this.postTree);
            this.updateCheckBoxDisableValue();
            this.searchLoading = false;

        }
        this.$emit('change', this.userGroupCondition);

    }

    private findAndDeleteCurrentNode(deptCode: any, children: any) {
        if (children && isArray(children) && children.length !== 0) {
            children.forEach((item: any) => {
                if (item.dept_id == deptCode) {
                    if (!this.showDropSearch) {
                        (this.$refs as any).tree2.setChecked(deptCode, false, true);
                    }
                    this.disabledChildrenOfData(item.children, false);
                } else {
                    this.findAndDeleteCurrentNode(deptCode, item.children);
                }
            });
        }
    }

    private findAndCheckCurrentNode(deptCode: any, children: any) {
        if (children && isArray(children) && children.length !== 0) {
            children.forEach((item: any) => {
                if (item.dept_id == deptCode) {
                    if (!this.showDropSearch) {
                        (this.$refs as any).tree2.setChecked(deptCode, true, true);
                    }
                    this.removeCheckedChildrenOfData(item.children)
                    this.disabledChildrenOfData(item.children, true);
                } else {
                    this.findAndCheckCurrentNode(deptCode, item.children);
                }
            });
        }
    }

    private async queryUserAndDepartByText(): Promise<void> {
        let result: any = {},
            data = {
                keyword: encodeURIComponent(this.filterText),
                selected: this.selectValCopy,
                helpSelect: this.helpVal,
                buList: this.BuValCopy
            }
            ;
        if (this.type == 'Pharma') {
            result = await this.departmentService.searchPharmaDepartAndUserByKeywords({ keyword: this.filterText });
            this.searchLoading = false;
        }
        else if (this.type == 'NONFF') {
            result = await this.departmentService.searchNonffDepartAndUserByKeywords(data);
            this.searchLoading = false;
        }
        else {
            result = await this.departmentService.searchOncoDepartAndUserByKeywords({ keyword: this.filterText });
            this.searchLoading = false;
        }
        this.searchLoading = false;
        //result为部门输出表deptOutputList和用户输出表userOutputList
        if (result.deptOutPutList && isArray(result.deptOutPutList)) {
            this.deptSearchList = result.deptOutPutList;
        }
        if (result.userOutputList && isArray(result.userOutputList)) {
            this.memberSearchList = result.userOutputList;
        }

        // 2. 根据userGroupCondition和list来初始化选中的节点，userGroupCondition中type=dept的需要在deptSearchList中匹配，type=user的在memberSearchList中匹配
        this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, this.userGroupCondition)
        this.updateCheckBoxDisableValue();
    }

    public initCheckedListBySearchText(deptList: any, memberList: any, userGroupCondition: any) {
        let deptCheckList: any[] = [], memberCheckList: any[] = [];
        userGroupCondition.forEach((item: any) => {
            if (item.type === UserGroupConditionType.POST) {
                let index = deptList.findIndex((dept: any) => dept.deptCode === item.dept.deptCode);
                if (index !== -1) {
                    deptCheckList.push(item.dept.deptCode);
                }
            } else if (item.type === UserGroupConditionType.USER) {
                let index = memberList.findIndex((member: any) => member.userId === item.user.userId);
                if (index !== -1) {
                    memberCheckList.push(item.user.userId);
                }
            }
        });
        this.deptCheckList = deptCheckList;
        this.memberCheckList = memberCheckList;
    }

    public updateCheckBoxDisableValue() {
        this.deptSearchList.forEach((item: any) => {
            let index = this.defaultDisabledDepartmentID.indexOf(item.deptCode);
            if (index !== -1) {
                this.$set(item, 'disabled', true);
            } else {
                this.$set(item, 'disabled', false);
            }
        });
    }


}
